<template>
  <v-row justify="center">
    <v-dialog :value="true" persistent max-width="540px" transition="dialog-transition">
      <v-card>
        <v-form @submit.prevent="create">
          <v-card-title>
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title class="text-h5 font-weight-bold" v-text="$t('project.createProjectDialog.newProject')" />
              </v-list-item-content>
              <v-spacer />
              <v-list-item-action>
                <v-btn icon color="primary" @click="$emit('closeDialog')">
                  <v-icon v-text="'close'" />
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="form.name"
              :error-messages="getFieldErrors('name')" :label="$t('common.name')"
              outlined dense class="mb-1" @blur="$v.form.name.$touch()"
            />
            <v-select
              v-model="form.type"
              :error-messages="getFieldErrors('type')" :label="$t('common.type')" :items="typeItems"
              outlined dense class="mb-1" @blur="$v.form.type.$touch()"
            />
          </v-card-text>
          <v-card-actions class="px-6 pb-5 pt-0 d-flex justify-center">
            <v-btn color="primary" depressed type="submit" v-text="$t('project.createProjectDialog.createProject')" />
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'CreateClusterDialog',
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
  },
  data: () => ({ form: { type: 'cluster', name: null } }),
  computed: {
    typeItems() {
      return [
        { text: this.$t('common.cluster'), value: 'cluster' },
      ]
    },
  },
  validations: { form: { type: { required }, name: { required } } },
  methods: {
    async create() {
      const { organizationId } = this
      if (!this.isFormValid()) return
      await this.runAsync(() => this.$store.dispatch('cluster/create', { organizationId, data: this.form }))
      this.$emit('closeDialog')
    },
  },
}
</script>
